<template>
  <div class="container fn text-white">
    <div>
      <router-link :to="{ name: 'deposit' }">
        <div class="all-title-head">
          {{ currency == 'lak' ? 'ຝາກເງິນກີບອໍໂຕ້' :'ຝາກເງິນບາດອໍໂຕ້' }}
        </div>
        <div class="dtx2">
          <span>
            <i
              class="fas fa-angle-left fa-2x"
              style="color: #fff; margin-top: 13px;"
            />
          </span>
        </div>
      </router-link>

      <div class="cs mt-2">
        <p class="alert">
          <span
            style="color: #fff"
          >1.) ລະບົບຈະເຕີມເງິນອັດຕະໂນມັດເຂົ້າຢູສເຊີຂອງທ່ານ &nbsp;<span
            style="color: #ffc800"
          >ພາຍໃນ 5  ວິນາທີ</span></span>
        </p>
        <p class="alert"><span
          style="color: #fff"
        >2.) ສາມາດໂອນເງິນເຂົ້າທະນາຄານໂດຍໃຊ້ &nbsp;<span
          style="color: #ffc800"
        >ຊື່ບັນຊີແລະເລກບັນຊີ</span>&nbsp; ທີ່ສະໝັກໂອນເຂົ້າມາເທົ່ານັ້ນ</span>
        </p>
      </div>

      <hr>
      <div>
        <div class="card9">
          <div class="logo">
            <img
              src="/bankIcon/bcel.png"
              alt="logo-bank"
              height="75"
              style="float: right"
              class="mt-2"
            >
          </div>
          <div class="chip">
            <img
              src="/icon/chip.png"
              alt="chip"
            >
            <span class="bankk">ທະນາຄານການຄ້າຕ່າງປະເທດລາວ</span>
          </div>
          <div class="number">
            {{ currency == 'lak' ? Agent.bcel_bank_accno : Agent.bcel_bank_accno_bath }}
          </div>
          <div class="name">
            {{ Agent.bcel_bank_accname }}
          </div>
          <div class="from" />
          <div class="to" />
          <div class="ring" />
        </div>
      </div>
      <button
        v-clipboard:copy="message"
        class="btnlogout mt-2"
        @click="showToast1()"
      >
        <span><i class="fad fa-copy fa-xs" /> ຄັດລັອກເລກບັນຊີ</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: '',
      selected: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
      Agent: '',
      currency: this.$route.params.currency,
    }
  },
  mounted() {
    this.getUser(this.userData)
    this.getAg()
  },
  methods: {
    showToast1() {
      this.$toast.success('คัดลอกเลขบัญชีสำเร็จ', {
        position: 'top-right',
        timeout: 3475,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'fad fa-paste',
        rtl: false,
      })
    },
    getUser(userData) {
      this.$http
        .get(`https://api.ufabangkok.com/api/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
    getAg() {
      this.$http
        .get('https://api.ufabangkok.com/api/agent/show')
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.Agent = response.data
          this.message = this.currency === 'lak' ? this.Agent.bcel_bank_accno : this.Agent.bcel_bank_accno_bath
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 540px;
}
.notice__list {
  font-size: 18px;
}
.notice__list {
  background-color: #fff;
  border: 1px solid rgb(255, 0, 0);
  padding: 15px 24px;
  border-radius: 20px;
  margin-bottom: 0.75rem;
}
p.alert {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
}
.btn-copy-2 {
  color: #000000;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
}
.btn {
  border-radius: 38px;
  width: 100vw;
  color: rgb(0, 0, 0);
  background-color: #ffc800;
}
.balance-cl {
  background-color: #4e2e7f;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
.balance-bay {
  background-color: #ffc323;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
p.highlight {
  line-height: 1.9;
  font-size: 2.6rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.neon1 {
  background: rgb(27, 12, 27);
  background: radial-gradient(circle, rgba(27, 12, 27, 0) 0%, #09081f 100%);
  border-radius: 5px;
  padding: 5px 5px;
  /* border: 1px solid blueviolet; */
  /* box-shadow: blueviolet 1px 1px 15px; */
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
span {
  font-size: 14px;
  font-weight: 300;
}
.dtx2 {
  position: relative;
  display: flex;
  margin-left: 5px;
  margin-top: -47px;
  justify-content: left;
  color: #000;
  font-weight: 600;
  align-items: left;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(#006db3, #29b6f6);
}
.circles {
  position: absolute;
  height: 270px;
  width: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle-1 {
  height: 180px;
  width: 180px;
  top: -50px;
  left: -60px;
}
.circle-2 {
  height: 200px;
  width: 200px;
  bottom: -90px;
  right: -90px;
  opacity: 0.8;
}
/* Background circles end */

/* .card-group {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.card7 {
  margin: auto;
  position: relative;
  height: 210px;
  max-width: 380px;
  border-radius: 15px;
  /* background: rgb(163, 0, 0); */
  background-color: #4e2e7f;
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.card8 {
  margin: auto;
  position: relative;
  height: 210px;
  max-width: 380px;
  border-radius: 15px;
  /* background: rgb(163, 0, 0); */
  background-color: rgb(0 147 69);
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.card9 {
  margin: auto;
  position: relative;
  height: 210px;
  max-width: 380px;
  border-radius: 15px;
  /* background: rgb(163, 0, 0); */
  background-color: #aa1212;
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.logo img,
.chip img,
.number,
.bankk,
.name,
.from,
.to,
.ring {
  position: absolute; /* All items inside card should have absolute position */
}

.logo img {
  top: 15px;
  right: 10px;
  width: 80px;
  height: auto;
  opacity: 0.8;
}

.chip img {
  top: 60px;
  left: 20px;
  width: 50px;
  height: auto;
  opacity: 0.8;
}
.bankk {
  top: 20px;
  left: 20px;
  font-size: 1.3rem;
  color: #fff;
}

.number,
.name,
.from,
.to {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  letter-spacing: 2px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

.number {
  left: 20px;
  font-size: 1.5rem;
  bottom: 65px;
  font-family: 'Nunito', sans-serif;
}

.name {
  font-size: 1rem;
  left: 20px;
  bottom: 35px;
}

.from {
  font-size: 1rem;
  bottom: 35px;
  right: 90px;
}

.to {
  font-size: 1rem;
  bottom: 35px;
  right: 20px;
}

/* The two rings on the card background */
.ring {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: transparent;
  border: 50px solid rgba(255, 255, 255, 0.1);
  bottom: -250px;
  right: -250px;
  box-sizing: border-box;
}

.ring::after {
  content: '';
  position: absolute;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: transparent;
  border: 30px solid rgba(255, 255, 255, 0.1);
  bottom: -80px;
  right: -110px;
  box-sizing: border-box;
}
.imh {
  max-width: 380px;
}
/* asdasd */
</style>
